import { axiosGet } from "@/utils/axiosWrapper";

function loadDataOld(view) {
  return axiosGet("/content/view/" + view)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.warn(error);
    });
}

function loadData(view, language) {
  return axiosGet("/content/view/" + view + "/" + language)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.warn(error);
    });
}

// function getContent(key, text, language) {
//   if (!text) return "";
//   if (!text.length)
//     // Wenn text noch nicht geladen ist
//     return "";

//   let myText = text.find((e) => e.textId === key);
//   if (myText) if (myText[language]) return myText[language];
//   if (myText["EN"]) return myText["EN"];

//   return `Loading...`;
// }

async function getAnswers(qID) {
  let question = await axiosGet("/question/questionsID/" + qID);
  question = question.data;
  let answers = await axiosGet("/algorithm/parent/" + question.questionsID);
  answers = answers.data;
  let answerIDs = answers.map((answer) => answer.questionsID);
  let state = getState(question.type);
  return {
    state: state,
    answerIDs: answerIDs,
  };
}

async function getNextQuestions(aID) {
  let questions = await axiosGet("/algorithm/parent/" + aID);
  questions = questions.data
    .filter((e) => e.type !== 4)
    .map((e) => e.questionsID);
  return questions;
}

async function mapTypeToQuestion(qID) {
  let question = await axiosGet("/question/questionsID/" + qID);
  question = question.data;
  return question;
}

function getState(type) {
  switch (type) {
    case 0:
      return 7;
    case 1:
      return 4;
    case 2:
      return 5;
    case 3:
      return 6;
    default:
      return -1;
  }
}

export {
  loadData,
  loadDataOld,
  //   getContent,
  getAnswers,
  getNextQuestions,
  mapTypeToQuestion,
};
